import {Controller} from 'stimulus'
import throttle from 'lodash/throttle'
import Swiper, {EffectFade, Navigation, Pagination} from 'swiper'

Swiper.use([EffectFade, Navigation, Pagination])

export default class extends Controller {
  static targets = ['prev', 'next', 'slider']

  connect() {
    this.initSlider()
  }

  initSlider() {
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 500,
      threshold: 35,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      breakpoints: {
        700: {
          threshold: 70,
        },
        1024: {
          threshold: 100,
        },
      },
    })
  }
}
